@import '@/styles/index';
.transaction-card {
  position: relative;

  .table-wrap {
    margin-top: 12px;

    .empty-table {
      .ant-table-thead {
        .ant-table-cell {
          height: 39px;
        }
      }

      .ant-table-tbody {
        display: none !important;
      }

      .ant-pagination {
        display: none !important;
      }
    }
  }

  .action-wrap {
    position: fixed;
    right: 85px;
    top: 80px;
    z-index: 20;
  }
}