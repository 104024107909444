.action-wrap {
    position: fixed;
    right: 85px;
    top: 80px;
    z-index: 20;
  }
  .anticon-search {
    color: #BEBEBE;
  }
  .ant-table-wrapper {
    margin-top: 16px;
  }
  @media (max-width: 767px) {
    .filter-wrap{
      display: block;
      .right-wrap{
        display: flex;
        justify-content: flex-end;
      }
    }
    .action-wrap{
      top: 65px;
      right: 15px;
    }
  }