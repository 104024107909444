.comps {
  width: 100%;
  padding: 16px;
  border-radius: 6px;
  background: white;
  .title{
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-list{
    margin-top: 16px;
    .info-item{
      display: flex;
      justify-content: space-between;
      padding: 10px;
      line-height: 22px;
      border-bottom: 1px solid #f0f0f0;
      &.bold{
        font-weight: 500;
      }
      & > div {
        width: 33.3%;
      }
      &-content{
        text-align: right;
      }
    }
    .ant-empty-normal{
      margin: 10px 0;
    }
  }
  @media (max-width: 768px) {
    padding: 5px;
    .title{
      font-size: 16px;
    }
  }
}
