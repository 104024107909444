.time-sheets-wrap {
  .main-top-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F0F0F0;
    .main-title {
      display: flex;
      align-items: center;
      height: 57px;
      font-size: 18px;
    }
  }

  .ant-select-selection-placeholder {
    color: #323232;
  }

  .ant-select-arrow {
    color: #5B5B5B;
  }
  
  .table-wrap {
    margin-top: 16px;
    .ant-table-tbody > tr.ant-table-row:hover {
      cursor: pointer;
    }
    .empty-table {
      .ant-table-thead {
        .ant-table-cell {
          height: 39px;
        }
      }
      
      .ant-table-tbody {
        display: none !important;
      }

      .ant-pagination {
        display: none !important;
      }
    }
  }
  @media (max-width: 767px) {
    .m-title {
      position: sticky;
      top: 50px;
      display: block;
      height: auto;
      padding-bottom: 10px;
    }
  }
}