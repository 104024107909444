.filter-container {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    @media (max-width: 768px) {
      width: 100%;
      .ant-space {
        gap: 6px !important;
      }
      .ant-form {
        width: 100%;
      }
      .ant-form-item {
        margin: 0 !important;
      }
      .ant-space {
        width: 100%;
        justify-content: space-between
      }
      .ant-space-item:nth-child(1),
      .ant-space-item:nth-child(2) {
        width: 100%;
      }
      
      /* 从第三个元素开始，两个一组 */
      .ant-space-item:nth-child(n+3) {
        width: calc(50% - 4px);
      }
      
      /* 如果是奇数个元素，最后一个独占一行 */
      .ant-space-item:last-child:nth-child(odd) {
        width: 100%;
      }
    }
  }