.table-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  padding: 8px 0;
  
  &-left {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
  }
  
  &-right {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  @media (max-width: 768px) {
    position: sticky;
    top: 0;
    right: 0;
    z-index: 99;
    flex-direction: column;
    align-items: stretch;
    padding: 8px 12px;
    background-color: #fff;
    border-bottom: 1px solid #DDD; 
    gap: 6px;
    &-right {
      justify-content: flex-start;
      gap: 6px;
      & > *:nth-child(2) {
        margin-left: auto;
      }
    
    }
    &-left {
      gap: 6px;
      opacity: 1;
      overflow: hidden;
      max-height: 400px;
      transition: all 0.4s ease;

      &.collapsed {
        max-height: 0;
        opacity: 0;
        margin: 0;
        padding: 0;
      }
    }
    .up-outlined {
      transition: transform 0.3s ease;
    }
  }
} 