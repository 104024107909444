.sales-summary-warp {
  padding-bottom: 22px;

  .title-wrap {
    height: 57px;
    font-size: 18px;
    background: white;
    padding-bottom: 16px;

    .title {
      height: 100%;
      margin: 0 22px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #DDDDDD;

    }
  }

  .content-wrap {
    padding: 22px;
    background: #F5F5F5;
    border-radius: 8px;
  }
  .row-box{
    margin-top: 16px;
  }
  .flex-box{
    margin: 16px -8px;
  }

  .filter-wrap {
    display: flex;
    justify-content: space-between;
    background-color: #F5F5F5;
    .filter-right-wrap {
      display: flex;
      gap: 0 10px;

      .ant-btn {
        border: 0;
      }
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 0;
    .m-title .title{
      padding-left: 15px;
    }
    .flex-box{
      overflow: hidden;
      margin: 16px 0 !important;
    }
    .content-wrap {
      margin-top: -16px;
      border-radius: 0;
      padding: 0;
      .chart-wrap {
        padding: 8px;
      }
    }
    .filter-wrap{
      margin-top: -10px;
      margin-bottom: -10px;
      padding: 10px 0;
      position: sticky;
      top: 40px;
      z-index: 99;
    }
    .filter-right-wrap{
      position: fixed;
      top: 60px;
      right: 13px;
      z-index: 10;
      .ant-btn{
        box-shadow: none;
      }
    }
  }
}


// ------- 打印样式 -------
@media print {

  .print-container {
    padding: 20px;

    .ant-table-wrapper table {
      width: 100% !important;
    }
    .ant-table-wrapper {
      overflow: hidden !important;
    }

    .ant-table-expanded-row-fixed {
      width: 100% !important;

      .ant-empty-image {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ant-empty-description {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ant-table-thead {
      display: table-header-group;
    }
    .ant-table-tbody {
      page-break-inside: avoid;
    }
    .sales-summary-total-summary-wrap {
      flex-wrap: wrap;
      gap: 12px 64px;
    }
  }

}