.gift-card {
  .main-title {
    display: flex;
    font-size: 18px;
    &:after {
      content: ' ';
      margin: 0 32px 0 16px;
      display: block;
      height: 28px;
      width: 1px;
      background: #DDD;
    }
  }
  .ant-tabs-top {
    overflow: inherit;

    .ant-tabs-content-holder {
      overflow: inherit;

      .ant-tabs-tabpane-active {
        overflow: inherit;
      }
    }
  }

  @media (max-width: 767px) {
    .main-title{
      font-size: 14px;
      &:after{
        margin: 0 10px;
      }
    }
    .ant-tabs .ant-tabs-tab{
      font-size: 14px;
    }
    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
}
