#header-title{
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
  .ant-tabs-top {
    overflow: inherit;
  
    .ant-tabs-content-holder {
      overflow: inherit;
  
      .ant-tabs-tabpane-active {
        overflow: inherit;
      }
    }
    & > .ant-tabs-nav {
      margin: 0;
    }
  }
  .more-tabs{
    .title:after {
      content: ' ';
      margin: 0 32px 0 16px;
      display: block;
      height: 28px;
      width: 1px;
      background: #DDD;
    }
  }
  .title {
    display: flex;
    align-items: center;
    font-size: 18px;
    height: 60px;
  }
  @media (max-width: 767px) {
    .more-tabs{
    .title:after {
      margin: 0 10px;
    }

    }
    .title{
      height: 46px;
      font-size: 14px;
      padding: 0 10px;
    }
    .ant-tabs .ant-tabs-tab{
      font-size: 14px;
    }
  }
}