.mobile-date-picker {
  display: flex;
  flex-direction: column;
  height: 100%;
  
  &-header {
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  &-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .close-icon {
      position: absolute;
      right: 0;
      font-size: 24px;
      color: #999;
      cursor: pointer;
      padding: 4px;
      
      &:active {
        opacity: 0.7;
      }
    }
  }
  
  .adm-calendar-picker-view {
    flex: 1;
    overflow: auto;
  }
  
  &-footer {
    padding: 16px;
    border-top: 1px solid #f0f0f0;
  }
  
  .adm-calendar-picker-view-header {
    display: none;
  }
}

.mobile-date-picker-button-view {
  width: 100%;
  padding: 1px 11px 1px 16px;
  background: #fff;
  border: 1px solid #DDD;
  border-radius: 4px;
  color: rgba(0,0,0,0.88);

  &::before {
    background-color: #fff !important;
  }
  
  .date-range-display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .date-range-content {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1;
      
      .date {
        font-size: 14px;
    }
      
      .separator {
        display: flex;
        align-items: center;
        padding: 0 15px;
        
        .line {
          width: 36px;
          height: 1px;
          background-color: rgba(0,0,0,0.88);
        }
      }
    }
    
    .calendar-icon {
      margin-left: 16px;
      font-size: 20px;
    }
  }
  
  &:hover {
    border-color: #ccc;
  }
}

.preset-scroll-container {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.preset-scroll-wrapper {
  display: flex;
  gap: 8px;
  white-space: nowrap;
  
  .preset-button {
    flex-shrink: 0;
  }
}